import React, { useState } from "react"
import {
  LgContentText,
  InputLabel,
  Input,
  InputContainer,
  TextArea,
  PrimaryBtn,
  FileUpload,
  HeaderText,
} from "../elements"
import styled from "styled-components"

const StyledForm = styled.form`
  display: grid;
  max-width: 480px;
  text-align: start;
  margin: 0 auto;
  gap: 38px;
  padding: 0 15px;
  button,
  h4 {
    margin: auto;
    max-width: fit-content;
    margin-bottom: 30px;
    padding: 0 30px;
  }
  .split {
    display: grid;
    gap: 38px 18px;
    grid-template-columns: 1fr;
    @media (min-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }
  }
`

const ApplyForm = ({ role }) => {
  const [formDetails, setFormDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    subject: role,
    phone: "",
    coverLetter: "",
  })
  const [contactState, setContactState] = useState()
  const [cv, setCV] = useState()

  let file = ""
  const handleSubmit = e => {
    e.preventDefault()
    if (cv) {
      let reader = new FileReader()
      reader.readAsDataURL(cv)
      reader.onload = function () {
        file = reader.result
        post(true)
      }
    }
    post(false)
  }

  const post = attachment => {
    fetch(`https://us-central1-toot-1ee81.cloudfunctions.net/contactUs`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: formDetails.email,
        subject: formDetails.subject,
        message:
          "Applicants Name: " +
          formDetails.firstName +
          " " +
          formDetails.lastName +
          ". Phone Number: " +
          formDetails.phone +
          ". Cover Letter: " +
          formDetails.coverLetter,
        attachments: attachment ? [file] : "",
      }),
    })
      .then(response =>
        response.status === 200 ? setContactState(true) : setContactState(false)
      )
      .catch(error => {
        console.error(error)
      })
  }

  return (
    <>
      <HeaderText>Apply for this job</HeaderText>
      <StyledForm onSubmit={e => handleSubmit(e)}>
        <div className="split">
          <InputContainer>
            <InputLabel>*First Name</InputLabel>
            <Input
              type="text"
              value={formDetails.firstName}
              onChange={e =>
                setFormDetails({
                  ...formDetails,
                  firstName: e.target.value,
                })
              }
              placeholder="Placeholder"
            />
          </InputContainer>
          <InputContainer>
            <InputLabel>*Last Name</InputLabel>
            <Input
              type="text"
              value={formDetails.lastName}
              onChange={e =>
                setFormDetails({
                  ...formDetails,
                  lastName: e.target.value,
                })
              }
              placeholder="Placeholder"
            />
          </InputContainer>
        </div>
        <InputContainer>
          <InputLabel>*Email</InputLabel>
          <Input
            type="text"
            value={formDetails.email}
            onChange={e =>
              setFormDetails({
                ...formDetails,
                email: e.target.value,
              })
            }
            placeholder="Placeholder"
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>*Mobile Number</InputLabel>
          <Input
            type="text"
            value={formDetails.phone}
            onChange={e =>
              setFormDetails({
                ...formDetails,
                phone: e.target.value,
              })
            }
            placeholder="Placeholder"
          />
        </InputContainer>
        <InputContainer>
          <InputLabel>*Cover Letter</InputLabel>
          <TextArea
            value={formDetails.coverLetter}
            onChange={e =>
              setFormDetails({
                ...formDetails,
                coverLetter: e.target.value,
              })
            }
            placeholder="Placeholder"
          />
        </InputContainer>
        <FileUpload file={cv} updateFile={setCV} />
        {contactState === true ? (
          <LgContentText>Thank you for contacting Toot.</LgContentText>
        ) : (
          <PrimaryBtn>Submit Application</PrimaryBtn>
        )}
      </StyledForm>
    </>
  )
}

export default ApplyForm
