import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery, graphql } from "gatsby"
import { HeaderLargeText, SubHeaderText, SubContentText } from "../elements"
import ApplyForm from "../components/apply-form"
import DynamicZone from "../components/dynamic-zone"
import Breadcrumbs from "../components/breadcrumbs"
import ReactSearchBox from "react-search-box"
import styled from "styled-components"
import searchIcon from "../assets/images/icons/search-icon.svg"
import { colorPrimary } from "../utilities/variables"
import { window } from "browser-monads"

const StyledInputWrapper = styled.div`
  max-width: 420px;
  margin: 0 auto;
  input {
    color: ${colorPrimary};
    background-image: url(${searchIcon});
    background-repeat: no-repeat;
    background-position: 97% center;
    margin: 0 15px 0 15px;
  }
  .react-search-box-dropdown {
    margin: 10px 15px;
  }
`

const ArticleTemplate = props => {
  const {
    title,
    content,
    description,
    slug,
    form,
    location,
    salary,
    breadcrumbs,
  } = props.pageContext

  const data = useStaticQuery(graphql`
    query {
      allStrapiBlogs {
        nodes {
          Slug
          ShortDescription
          Title
        }
      }
      allStrapiHelp {
        nodes {
          Slug
          Title
          Description
        }
      }
    }
  `)

  const dataContent = data.allStrapiBlogs.nodes.concat(data.allStrapiHelp.nodes)
  const searchData = []
  dataContent.map(item =>
    searchData.push({
      key: item.Slug,
      value: item.Title,
      type: item.Description ? "faqs" : "blogs",
    })
  )

  return (
    <Layout breadcrumb={breadcrumbs}>
      <SEO title={title} />
      {breadcrumbs && (
        <Breadcrumbs
          link={`/${breadcrumbs.toLowerCase()}`}
          text={`Back to all ${breadcrumbs}`}
        />
      )}
      <HeaderLargeText>{title}</HeaderLargeText>
      {slug === "faqs" && (
        <StyledInputWrapper>
          <ReactSearchBox
            placeholder="Search by topic or keywords"
            value=""
            data={searchData}
            onSelect={record =>
              (window.location.href = `${
                record.type === "faqs" ? "/faqs" : ""
              }/${record.key}`)
            }
            fuseConfigs={{
              threshold: 0.05,
            }}
          />
        </StyledInputWrapper>
      )}
      <SubHeaderText
        dangerouslySetInnerHTML={{ __html: description }}
      ></SubHeaderText>
      {form && (
        <SubContentText style={{ textAlign: "center" }}>
          Location: <b>{location}</b>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Salary: <b>{salary}</b>
        </SubContentText>
      )}
      {content.Content && <DynamicZone components={content.Content} />}
      {form && <ApplyForm role="Job Title Name" />}
    </Layout>
  )
}

export default ArticleTemplate
