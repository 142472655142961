import React from "react"
import styled from "styled-components"
import { SmallContentText } from "../elements"
import chevron from "../assets/images/icons/chevron.svg"
import { Link } from "gatsby"

const StyledBreadcrumbs = styled.div`
  background: #f7f7f8 0% 0% no-repeat padding-box;
  height: 45px !important;
  display: none;
  @media (min-width: 700px) {
    display: grid;
  }
  > div {
    max-width: 1300px;
    width: 100%;
    margin: 0 auto;
    align-self: center;
    padding: 0 calc(5% + 65px);
    a {
      text-decoration: none;
    }
    img {
      transform: rotate(90deg);
      margin-right: 15px;
    }
  }
`

const Breadcrumbs = ({ link, text }) => {
  return (
    <StyledBreadcrumbs>
      <div>
        <Link to={link}>
          <img src={chevron} alt="chevron" />
          <SmallContentText>{text}</SmallContentText>
        </Link>
      </div>
    </StyledBreadcrumbs>
  )
}

export default Breadcrumbs
